import { useState } from 'react';
import { ConfirmationDialogContext } from './DialogContext';

const BULK_EDIT_CONFIRMATION_DIALOG_INITIAL_STATE = {
  open: false,
  handleOkCallback: () => {},
  handleCancelCallback: () => {},
  selectedCount: 0,
  source: '',
  rows: [],
};

const REVIEW_DOCUMENTS_CONFIRMATION_DIALOG_INITIAL_STATE = {
  open: false,
  handleOk: () => {},
  documentId: '',
};

const ConfirmationDialogProvider = ({ children, ...props }) => {
  const [bulkEditConfirmation, setBulkEditConfirmation] = useState(BULK_EDIT_CONFIRMATION_DIALOG_INITIAL_STATE);

  const [reviewDocumentsConfirmation, setReviewDocumentsConfirmation] = useState(
    REVIEW_DOCUMENTS_CONFIRMATION_DIALOG_INITIAL_STATE
  );

  const resetBulkEditConfirmation = () => setBulkEditConfirmation(BULK_EDIT_CONFIRMATION_DIALOG_INITIAL_STATE);

  const resetReviewDocumentsConfirmation = () =>
    setReviewDocumentsConfirmation(REVIEW_DOCUMENTS_CONFIRMATION_DIALOG_INITIAL_STATE);

  return (
    <ConfirmationDialogContext.Provider
      value={{
        bulkEditConfirmation,
        setBulkEditConfirmation: (newState) => setBulkEditConfirmation((prevState) => ({...prevState, ...newState})),
        resetBulkEditConfirmation,
        reviewDocumentsConfirmation,
        setReviewDocumentsConfirmation,
        resetReviewDocumentsConfirmation,
      }}
      {...props}
    >
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

export default ConfirmationDialogProvider;
