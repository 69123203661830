import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Stack, TextField, DialogTitle, DialogContent, DialogActions, Alert, AlertTitle } from '@mui/material';
import { useGetIntegrationMetadataQuery, useLazyPostSearchQuery } from '../../../services/integrations/integrationsApi';
import { resetGlobalDialogues } from '../../../redux/slices/globalDialogueSlice';
import {
  setIntegratedCase,
  setIntegrationDocumentSelectionModal,
  setPendingSelectedCase,
} from '../../../redux/slices/integrationsSlice';
import { getIntegrationLogoUrl } from '../../../CustomerManagement/Integrations/Helpers';
import SearchResultsTable from './Components/SearchResultsTable';
import Title from '../../../CommonComponents/Title';

const IntegrationSearchModal = () => {
  const dispatch = useDispatch();

  const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
  const { displayName: integrationName, integrationId, displayLabels = [] } = integrationMetadata;

  const [searchQuery, { data: searchResults = [], isFetching: isSearchLoading, isUninitialized: isNotSearched }] =
    useLazyPostSearchQuery();

  const {
    integrationSearch: {
      data: { source },
    },
  } = useSelector((state) => state.GlobalDialogues);

  const [searchText, setSearchText] = useState('');
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const handleSearch = () => {
    if (searchText) {
      searchQuery({ integrationId, queryField: 'claimNumber', queryText: searchText });
    }
  };

  const handleClose = () => {
    dispatch(resetGlobalDialogues());
  };

  const handleSelectCase = () => {
    const selectedCase = searchResults.find((result) => result.id === rowSelectionModel[0]) ?? {};

    if (source === 'edit') {
      dispatch(setPendingSelectedCase({ ...selectedCase }));
    } else {
      dispatch(setIntegratedCase({ ...selectedCase }));
      dispatch(setIntegrationDocumentSelectionModal({ open: true, data: { allowChangingLinkedCase: true } }));
    }

    handleClose();
  };

  const shouldLimitRows = searchResults?.length > 50;

  const isSelectDisabled = rowSelectionModel.length === 0;

  const getHelperText = () => displayLabels.find((label) => label.labelId === 'search_helper_text')?.labelText ?? '';

  return (
    <>
      <DialogTitle>
        <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
        >
            <img
                src={getIntegrationLogoUrl(integrationId)}
                alt={integrationName}
                style={{
                    height: 32,
                }}
            />
            <Title>
              Search
            </Title>
        </Stack>
      </DialogTitle>

      <DialogContent>
        {shouldLimitRows && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            <AlertTitle>More than 50 search results</AlertTitle>
            Try another search to narrow the results
          </Alert>
        )}

        <Stack spacing={2} sx={{ mt: 2, alignItems: 'flex-start' }} direction="row">
          <TextField
            variant="outlined"
            fullWidth={true}
            autoFocus
            helperText={getHelperText()}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />

          <Button variant="outlined" color="secondary" disabled={!searchText} onClick={handleSearch}>
            Search
          </Button>
        </Stack>

        {!isNotSearched && (
          <SearchResultsTable
            rows={searchResults}
            isLoading={isSearchLoading}
            shouldLimitRows={shouldLimitRows}
            rowSelectionModel={rowSelectionModel}
            setRowSelectionModel={setRowSelectionModel}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>

        <Button variant="contained" color="secondary" disabled={isSelectDisabled} onClick={handleSelectCase}>
          Select case
        </Button>
      </DialogActions>
    </>
  );
};

export default IntegrationSearchModal;
