import { apiSlice } from '../apiSlice';
import { setToast } from "../../redux/slices/globalToastSlice";
import {
    setCurrentCodeEditorContent,
    setCodeEditorContentFetched,
    setInitialCodeEditorContent
} from '../../redux/slices/codeEditorSlice';
import { parseLines } from "../../DemandComponents/DemandForms/Utilities";

export const aiNarrativeGenerationApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        // GET /document/${documentId}/aiEnabledFields
        getAiEnabledFields: builder.query({
            query: (documentId) => ({
                url: `/document/${documentId}/aiEnabledFields`,
                method: 'GET'
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    console.log(error);
                    dispatch(setToast({message: 'Error fetching AI enabled fields', severity: 'error', isOpen: true, duration: 9000}));
                }
            }
        }),
        // GET /aiDataLoaders
        getAiDataLoaders: builder.query({
            query: () => ({
                url: `/aiDataLoaders`,
                method: 'GET'
            }),
        }),
        // GET /document/${documentId}/generateNarrative/${fieldName}
        generateNarrativeForField: builder.query({
            query: ({fieldName, documentId}) => ({
                url: `/document/${documentId}/generateNarrative/${fieldName}`,
                method: 'GET'
            }),
        }),
        // POST /document/${documentId}/generateNarrative
        generateNarrativeAdmin: builder.query({
            query: ({documentId, values}) => ({
                url: `/document/${documentId}/generateNarrative`,
                method: 'POST',
                body: JSON.stringify(values)
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
                try {
                    await queryFulfilled;
                    dispatch(setToast({message: 'Narrative generated successfully', severity: 'success', isOpen: true, duration: 2000}));
                } catch (error) {
                    console.log(error);
                    dispatch(setToast({message: 'Error generating AI narrative', severity: 'error', isOpen: true, duration: 9000}));
                }
            }
        }),
        // POST /document/${documentId}/getAiRequestData
        loadAiData: builder.query({
            query: ({documentId, values}) => ({
                url: `/document/${documentId}/getAiRequestData`,
                method: 'POST',
                body: JSON.stringify(values)
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled, getState }) {
                try {
                    dispatch(setCurrentCodeEditorContent({ parentComponentName: 'AiPromptBuilderPayload', content: ""}));
                    dispatch(setCodeEditorContentFetched({ parentComponentName: 'AiPromptBuilderPayload', fetched: false }));

                    const { data } = await queryFulfilled;
                    dispatch(setInitialCodeEditorContent({ parentComponentName: 'AiPromptBuilderPayload', content: parseLines(JSON.stringify(data)) }));
                    dispatch(setCurrentCodeEditorContent({ parentComponentName: 'AiPromptBuilderPayload', content: parseLines(JSON.stringify(data)) }));
                    dispatch(setCodeEditorContentFetched({ parentComponentName: 'AiPromptBuilderPayload', fetched: true }));
                } catch (error) {
                    console.log(error);
                    dispatch(setCurrentCodeEditorContent({ parentComponentName: 'AiPromptBuilderPayload', content: ""}));
                    dispatch(setToast({message: 'Error loading AI data', severity: 'error', isOpen: true, duration: 9000}));
                }
            }
        }),
    }),
})

export const {
    useGetAiEnabledFieldsQuery,  // these names are generated by rtk query automatically
    useGetAiDataLoadersQuery,
    useGenerateNarrativeForFieldQuery,
    useGenerateNarrativeAdminQuery,
    useLoadAiDataQuery,
} = aiNarrativeGenerationApi
