import React, { useState, useEffect } from 'react';
import { Stack, Box, Button, Card, CardContent, Typography, IconButton, CircularProgress, TextField, CardActions } from '@mui/material';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { setAddIntegrationModalOpen } from '../../redux/slices/integrationsSlice';
import { CustomMuiDialogWrapper } from '../../CommonComponents/CustomMuiDialogWrapper';
import AddIntegrationModal from './AddIntegrationModal';
import { Transition } from '../../Transition';
import { useGetIntegrationsForCustomerQuery, useGetIntegrationsQuery, usePostSaveIntegrationMutation } from '../../services/integrations/integrationsApi';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const IntegrationsView = () => {
    const dispatch = useDispatch();
    const { customerId } = useParams();

    const [isEditing, setIsEditing] = useState(false);
    const [editValues, setEditValues] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const [errors, setErrors] = useState({});

    const {
        data: integrations,
        error: integrationsError,
        isLoading: integrationsLoading
    } = useGetIntegrationsQuery();

    const {
        data: integrationsForCustomer,
        error: integrationsForCustomerError,
        isLoading: integrationsForCustomerLoading
    } = useGetIntegrationsForCustomerQuery({ customerId });

    const [saveIntegration, { isLoading: saveIntegrationLoading, error: saveIntegrationError }] = usePostSaveIntegrationMutation()

    const { addIntegrationModalOpen } = useSelector((state) => state.Integrations);

    const currentCustomerIntegration = integrationsForCustomer?.integrations?.[0];
    const currentIntegration = currentCustomerIntegration
        ? integrations?.find(integration => integration.integrationId === currentCustomerIntegration.integrationId)
        : null;

    const configurationFields = currentIntegration?.configurationFields || [];

    useEffect(() => {
        return () => {
            dispatch(setAddIntegrationModalOpen(false));
        };
    }, []);

    useEffect(() => {
        if (currentCustomerIntegration && configurationFields.length > 0) {
            const values = {};
            configurationFields.forEach(field => {
                const fieldValue = currentCustomerIntegration.configurationFieldValues.find(
                    value => value.fieldId === field.fieldId
                );
                values[field.fieldId] = fieldValue?.fieldValue || '';
            });
            setInitialValues(values);
            setEditValues(values);
        }
    }, [currentCustomerIntegration, configurationFields]);

    const handleEditClick = () => {
        setIsEditing(true);
        setEditValues({ ...initialValues });
        setErrors({});
    };

    const validateFields = () => {
        const newErrors = {};
        let isValid = true;

        configurationFields.forEach(field => {
            if (field.required && !editValues[field.fieldId]) {
                newErrors[field.fieldId] = 'This field is required';
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleSave = () => {
        if (!validateFields()) {
            return;
        }

        setIsEditing(false);
        saveIntegration({
            integrationId: currentIntegration.integrationId,
            customerId,
            fieldInfo: Object.keys(editValues).map(fieldId => ({
                fieldId,
                fieldValue: editValues[fieldId]
            }))
        });
        setInitialValues(editValues);
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditValues({ ...initialValues });
        setErrors({});
    };

    const handleChange = (fieldId) => (event) => {
        const newValue = event.target.value;
        setEditValues(prev => ({
            ...prev,
            [fieldId]: newValue
        }));

        if (configurationFields.find(field => field.fieldId === fieldId).required) {
            setErrors(prev => ({
                ...prev,
                [fieldId]: newValue ? '' : 'This field is required'
            }));
        }
    };


    if (integrationsLoading || integrationsForCustomerLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress color="secondary" />
            </Box>
        );
    }

    if (integrationsError || integrationsForCustomerError || saveIntegrationError) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <Typography color="error">`Error: ${integrationsError || integrationsForCustomerError || saveIntegrationError}`</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Stack direction="row" justifyContent="flex-end" mb={3}>
                <Button
                    color='secondary'
                    variant="contained"
                    startIcon={<IntegrationInstructionsOutlinedIcon />}
                    onClick={() => dispatch(setAddIntegrationModalOpen(true))}
                >
                    Add Integration
                </Button>
            </Stack>

            {currentIntegration && (
                <Card sx={{
                    maxWidth: 400,
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px'
                }}>
                    <CardContent>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
                            <Typography variant="h6" component="div">
                                {currentIntegration?.displayName || "Integration"}
                            </Typography>
                            {!isEditing && (
                                <IconButton
                                    color="secondary"
                                    size="small"
                                    onClick={handleEditClick}
                                >
                                    <EditOutlinedIcon />
                                </IconButton>
                            )}
                        </Stack>

                        <Stack spacing={2}>
                            {configurationFields.map((field) => (
                                <Box key={field.fieldId}>
                                    <Typography color="textSecondary" variant="body2" gutterBottom>
                                        {field.fieldLabel}
                                        {field.required && ' *'}
                                    </Typography>
                                    {isEditing ? (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            value={editValues[field.fieldId] || ''}
                                            onChange={handleChange(field.fieldId)}
                                            variant="outlined"
                                            type={'text'}
                                            required={field.required}
                                            error={!!errors[field.fieldId]}
                                            helperText={errors[field.fieldId]}
                                        />
                                    ) : (
                                        <Typography variant="body1">
                                            {editValues[field.fieldId] || ''}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        </Stack>
                    </CardContent>
                    {isEditing && (
                        <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                            <Button
                                startIcon={<CancelIcon />}
                                color="secondary"
                                onClick={handleCancel}
                                sx={{ mr: 1 }}
                            >
                                Cancel
                            </Button>
                            <Button
                                startIcon={<SaveIcon />}
                                variant="contained"
                                color="secondary"
                                onClick={handleSave}
                                disabled={saveIntegrationLoading || Object.values(errors).some(error => !!error)}
                            >
                                {saveIntegrationLoading ? 'Saving...' : 'Save'}
                            </Button>
                        </CardActions>
                    )}
                </Card>
            )}

            <CustomMuiDialogWrapper
                open={addIntegrationModalOpen}
                TransitionComponent={Transition}
            >
                <AddIntegrationModal />
            </CustomMuiDialogWrapper>
        </Box>
    );
}

export default IntegrationsView;