import React, { useEffect, useState } from 'react';
import PdfEditor from '../Features/PDF/PdfEditor/view.jsx';
import PdfRedactorDialog from './PdfRedactor/PdfRedactorDialog.jsx';
import { Dialog, AppBar, IconButton, Typography, Stack, Box, DialogContent, Button, DialogTitle, Container, Tooltip } from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Title from '../CommonComponents/Title';
import { Transition } from '../Transition';
import { theme } from '../Theme';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAllDocumentsOpen, setEditOneDocumentFileName, setDocumentEdit3Url, setRedactDocumentOpen, setEditDocumentPdfOpen, setFileDisabled, setFileSaveStatusMap } from '../redux/slices/demandDomSlice';
import { setSequencedDocumentFiles } from '../redux/slices/editDocumentsSlice';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { setFilesSequenceApi, deleteFile, getFileUrl } from '../api';
import { setShouldDeleteFile } from '../redux/slices/documentSlice';
import { setConfirmDeleteFile, setDocumentEditingConfirmation } from '../redux/slices/globalDialogueSlice';
import LoadingStatusChip from '../CommonComponents/Chips/LoadingStatusChip.jsx';
import { DiscardWork } from '../dialogs/DialogText.js';
import { apiSlice } from '../services/apiSlice.js';
import { setPdfUrlPollingLoadingSkeleton } from '../services/pdf/customSlice';
import { pollPdfRegenerationStatus } from '../services/pdf/thunks.js';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';
import { setToast } from '../redux/slices/globalToastSlice.js';
import { insightInvalidationTags } from '../MedicalsComponents/insights.js';
import { useParams } from 'react-router-dom';
import { getDocumentSourceDisplayName } from "../common-document";
import { useGetIntegrationsQuery} from '../services/integrations/integrationsApi';


const demandOrCoverLetter = (detectedType) => detectedType === "cover letter" || detectedType === "demand letter";

const EditDocuments = () => {
    const { documentId } = useParams();
    const dispatch = useDispatch();

    const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
        refetchOnMountOrArgChange: true,
    });

    const { editAllDocumentsOpen, editOneDocumentFileName, documentEditS3Url, editDocumentPdfOpen, redactDocumentOpen, fileDisabled, fileSaveStatusMap } = useSelector(state => state.DemandDom);
    const documentDataLoading = useSelector((state) => state.Document.documentDataLoading)[documentId] ?? false;
    const { shouldDeleteFile } = useSelector(state => state.Document);
    const { user } = useSelector((state) => state.User);
    const { sequencedDocumentFiles } = useSelector((state) => state.EditDocuments);
    const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery();


    const [isDirty, setIsDirty] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [file, setFile] = useState(null);

    useEffect(() => {
        return () => {
            dispatch(setEditAllDocumentsOpen(false));
        }
    }, [])

    useEffect(() => {
        if (editAllDocumentsOpen) {
            dispatch(setSequencedDocumentFiles(documentFiles));
        }
    }, [editAllDocumentsOpen])

    useEffect(() => {
        setIsDirty(false);
        dispatch(setFileSaveStatusMap({}));
    }, [editAllDocumentsOpen])

    useEffect(() => {
        if (shouldDeleteFile) {
            dispatch(setToast({ isOpen: true, message: `Deleting ${file?.fileEntityData?.fileName}...`, severity: 'info', duration: 1700 }));
            dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));  //set polling loading status before we poll for ui updates
            dispatch(setFileDisabled(file.fileEntityId));
            dispatch(setFileSaveStatusMap({ ...fileSaveStatusMap, [file.fileEntityId]: 'Deleting' }));
            deleteFile(file.fileEntityId, user)
                .then(() => {
                    const newLocalFileList = [...documentFiles].filter((localFile) => localFile.fileEntityId !== file.fileEntityId);
                    dispatch(setSequencedDocumentFiles(newLocalFileList));
                    dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
                    // Refresh insight entities data
                    dispatch(apiSlice.util.invalidateTags(insightInvalidationTags));
                    dispatch(pollPdfRegenerationStatus({ documentId, user }));
                    dispatch(setShouldDeleteFile(false));
                    setTimeout(() => {
                        dispatch(setToast({ isOpen: true, message: `${file?.fileEntityData?.fileName} deleted successfully`, severity: 'success' }));
                    }, 1700);

                    dispatch(setFileDisabled(''));
                    const newFileStatusMap = { ...fileSaveStatusMap };
                    delete newFileStatusMap[file.fileEntityId];
                    dispatch(setFileSaveStatusMap(newFileStatusMap));
                })
                .catch((error) => {
                    console.log(error);
                    dispatch(setToast({ isOpen: true, message: `Error deleting ${file?.fileEntityData?.fileName}`, severity: 'error' }));
                    dispatch(setFileDisabled(''));
                });
        }
    }, [shouldDeleteFile])

    const handleEditFileClick = async (file, action) => {
        setFile(file);

        const response = await getFileUrl(file.fileEntityId, user)
            .then(response => response.json())
            .catch(error => {
                console.log(error);
                dispatch(setToast({ isOpen: true, message: 'Error fetching file', severity: 'error' }));
            });

        const s3Url = response?.s3Url || null;
        s3Url && dispatch(setDocumentEdit3Url(s3Url));
        s3Url && dispatch(setEditOneDocumentFileName(file.fileEntityData.fileName));
        action === 'redact' && dispatch(setRedactDocumentOpen(true));
        action === 'edit' && dispatch(setEditDocumentPdfOpen(true));
    }

    const columns = [
        {
            field: "name",
            headerName: "File",
            width: 400,
            renderCell: (params) => {
                const showSaveStatus = fileSaveStatusMap[params.row.fileEntityId] !== undefined;
                const aliasDifferentThanName = params.row.fileEntityData.aliasFileName && (params.row.fileEntityData.aliasFileName !== params.row.fileEntityData.fileName)
                return (
                    <Stack
                        sx={{ width: "100%" }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                    >
                        <Stack >
                            <Typography variant="tableP1" color={demandOrCoverLetter(params.row.detectedType) ? "text.secondary" : "primary"}>
                                {aliasDifferentThanName ? params.row.fileEntityData.aliasFileName : params.row.fileEntityData.fileName}
                            </Typography>
                            {aliasDifferentThanName &&
                                <Typography variant="tableP2">
                                    {params.row.fileEntityData.fileName}
                                </Typography>
                            }
                        </Stack>
                        {showSaveStatus && (
                            <LoadingStatusChip isLoading={(fileDisabled === params.row.fileEntityId) && !redactDocumentOpen && !editDocumentPdfOpen} label={fileSaveStatusMap[params.row.fileEntityId]} />
                        )}
                    </Stack>
                );
            },
        },
        {
            field: "sourceSystemId",
            headerName: "Source",
            width: 150,
            minWidth: 100,
            renderCell: (params) => {
                const { sourceSystemId, detectedType } = params.row.fileEntityData;
                return (
                    <Stack>
                        <Typography variant="tableP1">
                            { !isIntegrationsLoading ? getDocumentSourceDisplayName(sourceSystemId, detectedType, integrations) : '' }
                        </Typography>
                    </Stack>
                );
            },
        },
        {
            field: "pages",
            headerName: "Number of pages",
            width: 150,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                return (
                    <Typography variant="tableP1">
                        {params.row?.fileEntityData?.totalPages || ''}
                    </Typography>
                );
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 150,
            headerAlign: 'center',
            renderCell: (params) => {
                const { fileEntityId, name: fileName } = params.row;
                const fileLoading = fileDisabled === fileEntityId;
                const lessThan2Pages = params.row?.fileEntityData?.totalPages < 2;
                return (
                    <Box>
                        {demandOrCoverLetter(params.row.detectedType) ? "" :
                            <Stack direction="row" spacing={1}>
                                <Tooltip title={"Edit"} arrow>
                                    <div>
                                        <IconButton
                                            aria-label="Edit"
                                            disabled={fileLoading}
                                            onClick={() => handleEditFileClick(params.row, 'edit')}
                                        >
                                            <EditOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip title='Redact' arrow>
                                    <div>
                                        <IconButton
                                            aria-label="Redact"
                                            disabled={fileLoading}
                                            onClick={() => handleEditFileClick(params.row, 'redact')}
                                        >
                                            <ContentCutOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip title={'Delete'} arrow>
                                    <div>
                                        <IconButton
                                            aria-label="Delete"
                                            disabled={fileLoading}
                                            onClick={() => {
                                                dispatch(setConfirmDeleteFile({ open: true, data: { fileName, fileEntityId, documentId, user } }));
                                                setFile(params.row)
                                            }}
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Stack>
                        }
                    </Box>
                );
            },
        },
    ];

    const handleClose = () => {
        !isDirty && dispatch(setEditAllDocumentsOpen(false));
        isDirty && dispatch(setDocumentEditingConfirmation({
            open: true,
            data: {
                text: DiscardWork.text,
                confirmText: DiscardWork.confirmText,
                cancelText: DiscardWork.cancelText,
                source: 'editDocuments',
                buttonColor: 'error'
            }
        }));
    }

    const handleSubmit = () => {
        setIsSaving(true);
        dispatch(setToast({ isOpen: true, message: "Saving document arrangement...", severity: 'info' }));
        const filesIdSequence = sequencedDocumentFiles.map(file => file.fileEntityId);

        dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

        setFilesSequenceApi(documentId, { orderedFiles: filesIdSequence }, user)
            .then(response => {
                if (response.status === 200) {
                    dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
                    dispatch(pollPdfRegenerationStatus({ documentId, user }));
                    // Refresh insight entities data
                    dispatch(apiSlice.util.invalidateTags(insightInvalidationTags));
                    setIsSaving(false);
                    setIsDirty(false);
                    dispatch(setToast({ isOpen: true, message: "Document arrangement saved successfully", severity: 'success' }));
                } else {
                    setIsSaving(false);
                }
            })
            .catch(error => {
                console.log(error);
                dispatch(setToast({ isOpen: true, message: "Error updating demand", severity: 'error' }));
            })
    }

    const rows = sequencedDocumentFiles.map(file => {
        return {
            ...file,
            id: file.fileEntityId,
            name: file?.fileEntityData?.fileName || '',
            detectedType: file?.fileEntityData?.detectedType || '',
            pinned: file?.fileEntityData?.detectedType === "cover letter" || file?.fileEntityData?.detectedType === "demand letter",
        }
    });

    const columnsWithOptions = columns.map((column) => {
        return {
            ...column,
            resizable: false,
            editable: false,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            disableColumnSelector: true,
            checkboxSelection: true,
            disableReorder: true,
        }
    });

    const handleRowOrderChange = (params, b) => {
        const filesCopy = [...sequencedDocumentFiles];
        const nonPinnedFiles = filesCopy.filter(file => file.fileEntityData.detectedType !== "cover letter" && file.fileEntityData.detectedType !== "demand letter")
        const pinnedFiles = filesCopy.filter(file => file.fileEntityData.detectedType === "cover letter" || file.fileEntityData.detectedType === "demand letter").sort((a, b) => a.fileEntityData.detectedType.localeCompare(b.fileEntityData.detectedType));
        const { oldIndex, targetIndex } = params;
        const movedFile = nonPinnedFiles.splice(oldIndex, 1)[0];
        nonPinnedFiles.splice(targetIndex, 0, movedFile);
        dispatch(setSequencedDocumentFiles([...pinnedFiles, ...nonPinnedFiles]));
        setIsDirty(true);
    }
    

    return (
        <Dialog
            fullScreen
            open={editAllDocumentsOpen}
            onClose={handleClose}
            TransitionComponent={Transition}
        >

            <AppBar sx={{ position: 'relative' }}>
                <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                    <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>

                            <IconButton onClick={handleClose} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                <Close color={'white'} />
                            </IconButton>

                            <Title color={theme.palette.white.main}>Edit documents</Title>

                        </Stack>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Button
                                disabled={!isDirty || isSaving || fileDisabled}
                                variant="contained"
                                color="white"
                                sx={{ '&:disabled': { backgroundColor: ("rgba(255,255,255,0.2)") } }}
                                onClick={handleSubmit}
                            >
                                {isSaving ? "Saving" : "Save"}
                            </Button>
                        </Stack>
                    </Stack>
                </DialogTitle>
            </AppBar>

            <Container sx={{ mt: '60px' }}>
                <DialogContent sx={{ marginTop: '0px', paddingTop: '0px', overflow: 'visible' }}>
                    <Box>

                        <Typography variant="body1" sx={{ my: 1 }}>
                            Drag and drop to arrange
                        </Typography>

                        <DataGridPro
                            rows={rows}
                            columns={columnsWithOptions}
                            rowReordering
                            disableRowSelectionOnClick
                            onRowOrderChange={handleRowOrderChange}
                            pinnedRows={{ top: rows.filter(row => row.pinned).sort((a, b) => a.detectedType.localeCompare(b.detectedType)), bottom: [] }}
                            hideFooter
                            loading={isSaving || fileDisabled || documentDataLoading}
                            sx={{
                                height: 'calc(100vh - 120px)',
                                '.MuiDataGrid-pinnedRows': {
                                    '&:hover': {
                                        cursor: 'not-allowed',
                                    }
                                },
                                '.MuiDataGrid-rowReorderCell--draggable': {
                                    color: 'rgba(0,0,0,0.54)'
                                }
                            }}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'
                            }
                        />

                    </Box>
                </DialogContent>
            </Container>

            {!!editOneDocumentFileName && !!documentEditS3Url && (
                <Dialog open={editDocumentPdfOpen} fullScreen={true} fullWidth={true} >
                    <DialogTitle className='dialog-title-blue' sx={{ height: "60px", width: "100%", zIndex: 1000 }} display="flex" alignItems="center" position={"fixed"}>
                        <Stack direction={"row"} style={{ width: "100%" }} alignItems={"center"} justifyContent={"space-between"}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <IconButton onClick={(e) => {
                                    dispatch(setEditDocumentPdfOpen(false));
                                }} aria-label="close" sx={{ mr: 2, '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.15)" } }}>
                                    <ArrowBack color={'white'} />
                                </IconButton>

                                <Title color={theme.palette.white.main}>Edit {editOneDocumentFileName}</Title>

                            </Stack>
                        </Stack>
                    </DialogTitle>
                    <PdfEditor
                        demandS3Url={documentEditS3Url}
                        user={user}
                        documentId={documentId}
                        file={file}
                        setIsSaving={setIsSaving}
                    />
                </Dialog>
            )}

            {!!editOneDocumentFileName && !!documentEditS3Url && (
                <Dialog open={redactDocumentOpen} fullScreen={true} fullWidth={true} >
                    <PdfRedactorDialog
                        demandS3Url={documentEditS3Url}
                        user={user}
                        documentId={documentId}
                        file={file}
                        setIsSaving={setIsSaving}
                    />
                </Dialog>
            )}

        </Dialog>
    )
}

export default EditDocuments;