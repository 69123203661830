import { apiSlice } from '../apiSlice';
import { mapFilesWithCalculatedStartPages } from './helpers';

const documentFilesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET /document/{documentId}/files/ids
    getAllFileIdsByDocumentId: builder.query({
      query: (documentId) => ({
        url: `/document/${documentId}/files/ids`,
        method: 'GET',
      }),
    }),
    // GET /file/{fileId}
    getFileById: builder.query({
      query: (fileId) => ({
        url: `/file/${fileId}`,
        method: 'GET',
      }),
    }),
    // custom query to specific files by specific file ids
    getFilesByIds: builder.query({
      queryFn: async (fileIds, { signal, dispatch, getState }, _extraOptions, baseQuery) => {
        try {
          const files = await Promise.all(fileIds.map((fileId) => baseQuery(`/file/${fileId}`)));
          return {
            data: files.map((file) => file.data),
          };
        } catch (error) {
          return { error };
        }
      },
    }),
    // custom query to get all associated files by document id
    getAllFilesByDocumentId: builder.query({
      queryFn: async (documentId, { signal, dispatch, getState }, _extraOptions, baseQuery) => {
        try {
          const files = await baseQuery(`/document/${documentId}/files`);
          
          return {
            // filter out the divider pages AFTER the start/end pages are calculated, this is the earlist point we can do this
            data: mapFilesWithCalculatedStartPages(files.data).filter(file => file?.fileEntityType !== 'DIVIDER_PAGE'),
          };

        } catch (error) {
          return { error };
        }
      },
    }),
    // POST /document/{documentId}/files
    postDocumentFiles: builder.mutation({
      query: ({documentId, files}) => ({
        url: `/document/${documentId}/files`,
        method: 'POST',
        body: JSON.stringify(files),
      })
    }),
    // POST /document/{documentId}/files/sendAiProcessing
    sendAiProcessing: builder.mutation({
      query: ({documentId, files}) => ({
        url: `/document/${documentId}/files/sendAiProcessing`,
        method: 'POST',
        body: JSON.stringify(files),
      })
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllFileIdsByDocumentIdQuery, // these names are generated by rtk query automatically
  useGetFileByIdQuery,
  useGetFilesByIdsQuery,
  useGetAllFilesByDocumentIdQuery,
  usePostDocumentFilesMutation,
} = documentFilesApi;
