import React, { useState } from 'react';
import { Button, IconButton, Menu, MenuItem, Box, Tooltip, ListItemText } from '@mui/material';
import { CloudUploadOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setDocumentUploaderList } from '../../redux/slices/demandDomSlice';
import { setIntegrationDocumentSelectionModal } from '../../redux/slices/integrationsSlice';
import { useGetIntegrationMetadataQuery } from '../../services/integrations/integrationsApi';

const UploadAdditionalDocumentsDropdown = ({
    handleFileProcessing,
    DOCUMENT_UPLOAD_FILE_TYPES,
    disabled
}) => {
    const dispatch = useDispatch();

    const { data: integrationMetadata = {} } = useGetIntegrationMetadataQuery();
    const { displayName: integrationName = '', integrationId = '' } = integrationMetadata;

    const { integratedCase: { id: matterId, claimNumber }, } = useSelector((state) => state.Integrations);

    const hasLinkedCase = Boolean(matterId && claimNumber);
    const showIntegration = Boolean(integrationId);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = e => setAnchorEl(e.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleFileChange = (e) => {
        const uniqueFiles = handleFileProcessing(e.target.files);
        dispatch(setDocumentUploaderList(uniqueFiles));
    };

    const handleUploadFromIntegration = () => {
        handleClose();
        dispatch(setIntegrationDocumentSelectionModal({ open: true, data: { allowChangingLinkedCase: true } }));
        setAnchorEl(null);
    }

    const { documentUploaderList } = useSelector((state) => state.DemandDom);

    const renderMenu = () => (
        <Menu
            disabled={disabled}
            sx={{ zIndex: 9999 }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem
                onClick={() => setAnchorEl(null)}
            >
                <label htmlFor="raised-button-file">
                    <ListItemText>
                        Upload from computer
                    </ListItemText>
                </label>
            </MenuItem>

            {showIntegration && (
                <Tooltip title={!hasLinkedCase ? 'Search and select a case to upload documents' : ""}>
                    <Box>
                        <MenuItem
                            onClick={handleUploadFromIntegration}
                            disabled={!hasLinkedCase}
                        >
                            <ListItemText>
                                Upload from {integrationName}
                            </ListItemText>
                        </MenuItem>
                    </Box>
                </Tooltip>
            )}
        </Menu>
    );

    return (
        <>
            <input
                accept={DOCUMENT_UPLOAD_FILE_TYPES}
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleFileChange}
            />

            <Box sx={{ display: { xs: 'none', sm: 'none', lg: 'block' } }}>
                <Button
                    onClick={handleClick}
                    variant="outlined"
                    color="white"
                    startIcon={<CloudUploadOutlined />}
                >
                    Upload documents
                </Button>
                {renderMenu()}
            </Box>

            <Box sx={{ display: { xs: 'block', sm: 'block', lg: 'none' } }}>
                <Tooltip title="Upload documents">
                    <span>
                        <IconButton
                            size="small"
                            onClick={handleClick}
                            color="white"
                        >
                            <CloudUploadOutlined />
                        </IconButton>
                    </span>
                </Tooltip>
                {renderMenu()}
            </Box>
        </>
    );
};

export default UploadAdditionalDocumentsDropdown;